import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar';

const Сахар = () => (
    <Layout>
      <Helmet>
          <title>Реагенты Сахарного Производства</title>
      </Helmet>
      <Header 
        styleName='header header-sugar'
        title='Сахарная индустрия'/>
      <div className="columns">
        <div className="column content">
          <div className="title-small-nomargintop title-small content-title">Реагенты для технологического цикла производства сахара</div>          
          <div className="content-text">В сферу интересов  ЗАО “Inžinerinė ekologija“ входит разработка высокотехнологичных решений и поставка качественных химических вспомогательных материалов и современного оборудования для технологического процесса в сахарной промышленности:</div>
          <ul>
              <li className="content-text">Высоко эффективные антискаланты для обработки сахарного сиропа выпарной установки</li>
              <li className="content-text">Дезинфекция диффузионной установки и предотвращение биообрастаний на всем производственном цикле, предотвращения формирования декстрана и снижения сахарности, успешный контроль Leuconostoc бактерий</li>
              <li className="content-text">Пеногасители органические для транспортно-моющей воды и диффузионного сока, успешно ингибируют и предотвращающие пенообразование</li>
              <li className="content-text">Осаждение и осветление первичного сока с применением синтетических коагулянтов и флокулянтов</li>
              <li className="content-text">Моющие концентраты для очистки процессного оборудования, работающего в условиях высоких температур</li>
              <li className="content-text">Дозирующее оборудование: насосы-дозаторы, автоматические дозирующие установки</li>
          </ul>
        </div>
        <Sidebar
        analysis='Анкеты'
        boiler='Бойлер'
        cooling='Охлаждение'
        ro='Осмос'
        broshure='IN-ECO брошура'
        certificates='Сертификаты'
        russian={true}
        />
      </div>
    </Layout>
)

export default Сахар